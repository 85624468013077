import { firebaseApp } from './../firebase';
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

// Action types
export const LOAD_GROWTH_BY_HANDLE_START = 'LOAD_GROWTH_BY_HANDLE_START';
export const LOAD_GROWTH_BY_HANDLE_SUCCESS = 'LOAD_GROWTH_BY_HANDLE_SUCCESS';
export const LOAD_GROWTH_BY_HANDLE_ERROR = 'LOAD_GROWTH_BY_HANDLE_ERROR';

// Load posts by handle actions
export const loadGrowthByHandleStart = () => {
    return { type: LOAD_GROWTH_BY_HANDLE_START };
};

export const loadGrowthByHandleSuccess = (posts) => {
    return { type: LOAD_GROWTH_BY_HANDLE_SUCCESS, payload: posts };
};

export const loadGrowthByHandleError = (errorMessage) => {
    return { type: LOAD_GROWTH_BY_HANDLE_ERROR, payload: errorMessage };
};

export const loadGrowthByHandle = (userId, handle) => {
    return async (dispatch) => {
        try {
            dispatch(loadGrowthByHandleStart());
            const firestore = getFirestore(firebaseApp);
            const growthQuerySnapshot = await getDocs(query(collection(firestore, "dailyGrowthStat"), where("populated", "==", true), where("userId", "==", userId), where("handle", "==", handle)));
            const growth = growthQuerySnapshot.docs.map((doc) => doc.data());

            dispatch(loadGrowthByHandleSuccess(growth));
        } catch (error) {
            dispatch(loadGrowthByHandleError(error.message));
        }
    };
};

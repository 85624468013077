import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { combineReducers } from 'redux';
import { auth } from './auth/reducer';
import { operations } from './operations/reducer';
import { error } from './error/reducer';
import { handle } from './handles/reducer';
import { scheduler } from './scheduler/reducer';
import { growth } from './growth/reducer';
import { bots } from './bots/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    auth,
    operations,
    error,
    handle,
    scheduler,
    growth,
    bots,
});

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

export default store;

import { CLEAR_ERROR } from "./actions";

// Initial state
const initialState = {
    message: null
};

// Reducer function
export function error(state = initialState, action) {
    if (action.type.endsWith('_ERROR')) {
        console.error(action.payload);
        return {
            ...state,
            message: action.payload
        };
    } else if (action.type === CLEAR_ERROR) {
        return {
            ...state,
            message: null
        };
    }
    return state;
};
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from './actions';
import { Button, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import { formStyle } from './../styles';
import skytoolbox from './SkyToolbox.png';

const rootStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
};

const inputWidth = 380;

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username && password) {
            dispatch(login(username, password));
        }
    };

    return (
        <Paper style={rootStyle}>
            <img src={skytoolbox} alt="Sky Toolbox" style={{ width: '250px', marginBottom: '8px' }} />
            <h2 style={{ marginBottom: '24px' }}>Login</h2>
            <form style={{ ...formStyle, alignItems: 'center' }} onSubmit={handleSubmit}>
                <TextField
                    label="Username"
                    type="text"
                    id="username"
                    value={username}
                    required
                    margin="normal"
                    onChange={handleUsernameChange}
                    sx={{ width: inputWidth }}
                />
                <TextField
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    required
                    margin="normal"
                    onChange={handlePasswordChange}
                    sx={{ width: inputWidth }}
                />
                <Button variant="contained" color="primary" type="submit" disabled={!username || !password} sx={{ m: 1, p: 2, width: inputWidth }}>Login</Button>
            </form>
        </Paper >
    );
};

export default LoginPage;

import { LOAD_GROWTH_BY_HANDLE_SUCCESS } from "./actions";

// Initial state
const initialState = {
    post: null,
    growthByHandle: null,
};

// Reducer function
export const growth = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_GROWTH_BY_HANDLE_SUCCESS:
            return {
                ...state,
                growthByHandle: action.payload,
            };
        default:
            return state;
    }
};

import { GET_POST_SUCCESS, LOAD_POSTS_BY_HANDLE_SUCCESS } from "./actions";

// Initial state
const initialState = {
    post: null,
    postsByHandle: null,
};

// Reducer function
export const scheduler = (state = initialState, action) => {
    switch (action.type) {
        case GET_POST_SUCCESS:
            return {
                ...state,
                post: action.payload,
            };
        case LOAD_POSTS_BY_HANDLE_SUCCESS:
            return {
                ...state,
                postsByHandle: action.payload,
            };
        default:
            return state;
    }
};

import React, { useCallback, useState } from 'react';
import { TextField, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectedImageStyle, VisuallyHiddenInput, formStyle } from './../styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';


const PostEditForm = ({ handleSubmit, post, handlePostChange, photoSrcs, handleImagesChange }) => {
    const [changed, setChanged] = useState(false);

    const changeImagesInternal = useCallback((e) => {
        const images = [];
        for (const file of e.target.files) {
            const fileURL = URL.createObjectURL(file);
            images.push({ file, fileURL });
        }

        handleImagesChange(images);
        setChanged(true);
    }, [handleImagesChange]);

    const removeImagesInternal = useCallback(() => {
        handleImagesChange([]);
        setChanged(true);
    }, [handleImagesChange]);

    const handlePostTextChange = useCallback((e) => {
        handlePostChange({ ...post, text: e.target.value });
        setChanged(true);
    }, [post, handlePostChange]);

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <div>Status: <b>{post?.status}</b></div>
            <TextField
                label="Text"
                value={post?.text || ""}
                margin="normal"
                onChange={handlePostTextChange}
                style={{ width: '100%' }}
                multiline
                minRows={8}
                maxRows={8}
                disabled={post?.status === 'published'}
            />
            <DateTimePicker
                label="Basic date time picker"
                value={post?.scheduledFor ? dayjs(post?.scheduledFor) : dayjs()}
                margin="normal"
                disabled={post?.status === 'published'}
                onChange={(value) => handlePostChange({ ...post, scheduledFor: value.toISOString() })}
                sx={{ mt: 2 }}
            />
            {(photoSrcs ?? []).map((photoSrc) => (<img src={photoSrc} style={selectedImageStyle} alt='Photo' />))}
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} margin="normal" sx={{ mt: 2 }} disabled={post?.status === 'published'}>
                Change Image(s)
                <VisuallyHiddenInput type="file" onChange={changeImagesInternal} multiple />
            </Button>
            <Button component="label" variant="contained" startIcon={<DeleteIcon />} margin="normal" onClick={removeImagesInternal} sx={{ mt: 2 }} disabled={post?.status === 'published'}>
                Remove Image(s)
            </Button>
            <Button type="submit"
                variant="contained"
                onClick={handleSubmit}
                disabled={!changed || !post?.text || post.text.length > 300 || !post?.scheduledFor || dayjs(post?.scheduledFor).isBefore(dayjs())} style={{ alignSelf: 'center' }}>
                Save
            </Button>
            {post?.text && post.text.length > 300 && <div style={{ color: 'red' }}>Text is too long</div>}
            {!post?.text && <div style={{ color: 'red' }}>Text is required</div>}
            {!post?.scheduledFor && <div style={{ color: 'red' }}>Scheduled date is required</div>}
            {post?.scheduledFor && dayjs(post?.scheduledFor).isBefore(dayjs()) && <div style={{ color: 'red' }}>Scheduled date must be in the future</div>}
        </form >
    );
};

export default PostEditForm;

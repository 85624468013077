import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { mainPageRootStyle } from './../styles';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { deletePost, loadPostsByHandle } from './actions';

function PostsListPage() {
    const dispatch = useDispatch();
    const handles = useSelector(state => state.handle.handles);
    const posts = useSelector(state => state.scheduler.postsByHandle);
    const userId = useSelector(state => state.auth.user?.uid);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedHandle = queryParams.get('handle');

    useEffect(() => {
        dispatch(loadPostsByHandle(userId, selectedHandle));
    }, [dispatch, selectedHandle, userId]);

    const handleDelete = (id) => {
        dispatch(deletePost(id));
    };

    const handleSelectedHandleChange = (event) => {
        navigate(`/scheduler?handle=${event.target.value}`);
    };

    const handleAddPost = () => {
        navigate(`/scheduler/add?handle=${selectedHandle}`);
    };

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h1>Photos</h1>
            <Button variant="contained" onClick={handleAddPost} sx={{ alignSelf: 'flex-start' }}>
                Schedule Post
            </Button>
            <Select
                value={selectedHandle}
                onChange={handleSelectedHandleChange}
                label="Handle"
                sx={{ mt: 2, mb: 2, maxWidth: 600 }}
            >
                {(handles ?? []).map(handle => {
                    return <MenuItem key={handle.handle} value={handle.handle}>{handle.handle}</MenuItem>
                })}
            </Select>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Id</b></TableCell>
                            <TableCell><b>Text</b></TableCell>
                            <TableCell><b>Images</b></TableCell>
                            <TableCell style={{minWidth: "170px"}} ><b>Scheduled for</b></TableCell>
                            <TableCell style={{minWidth: "100px"}} ><b>Status</b></TableCell>
                            <TableCell style={{minWidth: "100px"}} align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(posts ?? []).map((post) => (
                            <TableRow
                                key={post.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {post.id}
                                </TableCell>
                                <TableCell>{post.text}</TableCell>
                                <TableCell>{post.images?.length ?? 0}</TableCell>
                                <TableCell style={{minWidth: "170px"}} >{post.scheduledFor.substring(0,19)}</TableCell>
                                <TableCell style={{minWidth: "100px"}}>{post.status}</TableCell>
                                <TableCell style={{minWidth: "100px"}} align="right">
                                    <IconButton onClick={() => navigate(`/scheduler/edit/${post.id}`)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(post.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default PostsListPage;

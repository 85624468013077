import CircularProgress from '@mui/material/CircularProgress';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Overlay from './components/overlay/Overlay';
import ErrorDialog from './error/ErrorDialog';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from './error/actions';
import LoginPage from './auth/LoginPage';
import MainPage from './main/MainPage';
import HandlesListPage from './handles/HandlesListPage';
import HandleAddPage from './handles/HandleAddPage';
import HandleEditPage from './handles/HandleEditPage';
import PostsListPage from './scheduler/PostsListPage';
import PostAddPage from './scheduler/PostAddPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PostEditPage from './scheduler/PostEditPage';
import DashboardPage from './growth/DashboardPage';
import BotsConfigPage from './bots/BotsConfigPage';

function App() {
  const error = useSelector(state => state.error.message);
  const progress = useSelector(state => !!state.operations.operationsCounter);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <ErrorDialog open={!!error} message={error} onClose={() => dispatch(clearError())} />
        <Overlay isOpen={progress}>
          <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
        </Overlay>
        {isAuthenticated ?
          <Router>
            <Routes>
              <Route path="/" element={<MainPage />}>
                <Route path="" element={<Navigate to="handles" />} />
                <Route path="growth" element={<DashboardPage />} />
                <Route path="handles" element={<HandlesListPage />} />
                <Route path="handles/add" element={<HandleAddPage />} />
                <Route path="handles/edit/:handleId" element={<HandleEditPage />} />
                <Route path="scheduler" element={<PostsListPage />} />
                <Route path="scheduler/add" element={<PostAddPage />} />
                <Route path="scheduler/edit/:postId" element={<PostEditPage />} />
                <Route path="bots" element={<BotsConfigPage />} />
              </Route>
            </Routes>
          </Router> :
          <LoginPage />}
      </div>
    </LocalizationProvider>
  );
}

export default App;

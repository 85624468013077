import { firebaseApp } from './../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { generateKey } from "./../utils/keyUtils";
import { getFirestore, collection, setDoc, getDocs, deleteDoc, doc, getDoc, query, where } from "firebase/firestore";
import { uploadToStorage } from '../utils/storageUtils';

// Action types
export const CREATE_POST_START = 'CREATE_POST_START';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_ERROR = 'CREATE_POST_ERROR';
export const DELETE_POST_START = 'DELETE_POST_START';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_ERROR = 'DELETE_POST_ERROR';
export const GET_POST_START = 'GET_POST_START';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_ERROR = 'GET_POST_ERROR';
export const UPDATE_POST_START = 'UPDATE_POST_START';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export const UPDATE_POST_ERROR = 'UPDATE_POST_ERROR';
export const LOAD_POSTS_BY_HANDLE_START = 'LOAD_POSTS_BY_HANDLE_START';
export const LOAD_POSTS_BY_HANDLE_SUCCESS = 'LOAD_POSTS_BY_HANDLE_SUCCESS';
export const LOAD_POSTS_BY_HANDLE_ERROR = 'LOAD_POSTS_BY_HANDLE_ERROR';


// Create post actions
export const createPostStart = () => {
    return { type: CREATE_POST_START };
};

export const createPostSuccess = () => {
    return { type: CREATE_POST_SUCCESS };
};

export const createPostError = (errorMessage) => {
    return { type: CREATE_POST_ERROR, payload: errorMessage };
};

export const createPost = (post, images, userId, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createPostStart());
            const id = generateKey();
            const storagePaths = [];
            for (const image of images) {
                storagePaths.push(await uploadToStorage(image, post, id, userId));
            }

            const postData = {
                ...post,
                images: storagePaths,
                status: "scheduled",
                userId,
                id,
            };

            const firestore = getFirestore(firebaseApp);
            await setDoc(doc(firestore, "scheduledPosts", id), postData);
            navigate(`/scheduler?handle=${post.handle}`);
            dispatch(createPostSuccess());
        } catch (error) {
            dispatch(createPostError(error.message));
        }
    };
};

// Delete post actions
export const deletePostStart = () => {
    return { type: DELETE_POST_START };
};

export const deletePostSuccess = () => {
    return { type: DELETE_POST_SUCCESS };
};

export const deletePostError = (errorMessage) => {
    return { type: DELETE_POST_ERROR, payload: errorMessage };
};

export const deletePost = (id) => {
    return async (dispatch) => {
        try {
            dispatch(deletePostStart());
            const firestore = getFirestore(firebaseApp);
            const storage = getStorage(firebaseApp);
            const postRef = doc(firestore, "scheduledPosts", id);
            const postSnapshot = await getDoc(postRef);
            const images = postSnapshot.data().images ?? [];
            const handle = postSnapshot.data().handle ?? [];
            const userId = postSnapshot.data().userId;
            await deleteDoc(postRef);
            for (const image of images) {
                const imageRef = ref(storage, image);
                await deleteObject(imageRef);
            }
            dispatch(deletePostSuccess());
            dispatch(loadPostsByHandle(userId, handle));
        } catch (error) {
            dispatch(deletePostError(error.message));
        }
    };
};

// Get post by ID actions
export const getPostStart = () => {
    return { type: GET_POST_START };
};

export const getPostSuccess = (post) => {
    return { type: GET_POST_SUCCESS, payload: post };
};

export const getPostError = (errorMessage) => {
    return { type: GET_POST_ERROR, payload: errorMessage };
};

export const getPost = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getPostStart());
            const firestore = getFirestore(firebaseApp);
            const postRef = doc(firestore, "scheduledPosts", id);
            const postSnapshot = await getDoc(postRef);
            if (postSnapshot.exists()) {
                const post = postSnapshot.data();
                const storage = getStorage(firebaseApp);
                const urls = [];
                for (const image of post.images) {
                    const imageRef = ref(storage, image);
                    const imageURL = await getDownloadURL(imageRef);
                    urls.push(imageURL);
                }
                const updatedPost = { ...post, imageUrls: urls };

                dispatch(getPostSuccess(updatedPost));
            } else {
                dispatch(getPostError("Post not found"));
            }
        } catch (error) {
            dispatch(getPostError(error.message));
        }
    };
};

// Update post actions
export const updatePostStart = () => {
    return { type: UPDATE_POST_START };
};

export const updatePostSuccess = () => {
    return { type: UPDATE_POST_SUCCESS };
};

export const updatePostError = (errorMessage) => {
    return { type: UPDATE_POST_ERROR, payload: errorMessage };
};

export const updatePost = (id, post, images, userId, updateImages, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(updatePostStart());
            const firestore = getFirestore(firebaseApp);
            const storage = getStorage(firebaseApp);
            const postRef = doc(firestore, "scheduledPosts", id);
            const postDoc = await getDoc(postRef);
            let postData = postDoc.data();
            postData.text = post.text;
            postData.scheduledFor = post.scheduledFor;
            postData.status = "scheduled";
            let imagesToRemove = [];

            if (updateImages) {
                imagesToRemove = postData.images;
                const newImages = [];
                for (const image of images) {
                    newImages.push(await uploadToStorage(image, post, id, userId));
                }

                postData.images = newImages;
            }

            await setDoc(postRef, postData);
            for (const image of imagesToRemove) {
                const imageRef = ref(storage, image);
                await deleteObject(imageRef);
            }
            dispatch(loadPostsByHandle(userId, post.handle));
            navigate(`/scheduler?handle=${post.handle}`);
            dispatch(updatePostSuccess());
        } catch (error) {
            dispatch(updatePostError(error.message));
        }
    };
};

// Load posts by handle actions
export const loadPostsByHandleStart = () => {
    return { type: LOAD_POSTS_BY_HANDLE_START };
};

export const loadPostsByHandleSuccess = (posts) => {
    return { type: LOAD_POSTS_BY_HANDLE_SUCCESS, payload: posts };
};

export const loadPostsByHandleError = (errorMessage) => {
    return { type: LOAD_POSTS_BY_HANDLE_ERROR, payload: errorMessage };
};

export const loadPostsByHandle = (userId, handle) => {
    return async (dispatch) => {
        try {
            dispatch(loadPostsByHandleStart());
            const firestore = getFirestore(firebaseApp);
            const postsQuerySnapshot = await getDocs(query(collection(firestore, "scheduledPosts"), where("userId", "==", userId), where("handle", "==", handle)));
            const posts = postsQuerySnapshot.docs.map((doc) => doc.data());

            dispatch(loadPostsByHandleSuccess(posts));
        } catch (error) {
            dispatch(loadPostsByHandleError(error.message));
        }
    };
};

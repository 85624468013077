import { GET_BOTS_SUCCESS } from "./actions";

// Initial state
const initialState = {
    botsConfigByHandle: null,
};

// Reducer function
export const bots = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOTS_SUCCESS:
            return {
                ...state,
                botsConfigByHandle: action.payload,
            };
        default:
            return state;
    }
};

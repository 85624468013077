import { GET_HANDLE_SUCCESS, LOAD_HANDLES_SUCCESS } from "./actions";

// Initial state
const initialState = {
    handles: null,
    handle: null,
};

// Reducer function
export const handle = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_HANDLES_SUCCESS:
            return {
                ...state,
                handles: action.payload,
            };
        case GET_HANDLE_SUCCESS:
            return {
                ...state,
                handle: action.payload,
            };
        default:
            return state;
    }
};

import React, { Fragment, useEffect } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { Paper, Drawer, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdbIcon from '@mui/icons-material/Adb';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { loadHandles } from '../handles/actions';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from '../components/overlay/Overlay';

const drawerWidth = 240;

function MainPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const userId = useSelector(state => state.auth.user?.uid);
    const handles = useSelector(state => state.handle.handles);

    useEffect(() => {
        dispatch(loadHandles(userId));
    }, [dispatch, userId]);

    const isPathSelected = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <Fragment>
            <Overlay isOpen={!handles}>
                <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
            </Overlay>
            {handles != null ?
                <Paper sx={{ display: 'flex', height: '100%', position: 'fixed', minWidth: '100%' }}>
                    <Drawer variant="permanent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        <List>
                            {handles.length > 0 ?
                                <Fragment>
                                    <ListItem button component={Link} to={`/growth?handle=${handles[0].handle}`} selected={isPathSelected('/growth')}>
                                        <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                                            <ListItemIcon>
                                                <TrendingUpIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Growth" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem button component={Link} to={`/scheduler?handle=${handles[0].handle}`} selected={isPathSelected('/scheduler')}>
                                        <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                                            <ListItemIcon>
                                                <CalendarMonthIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Scheduler" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem button component={Link} to={`/bots?handle=${handles[0].handle}`} selected={isPathSelected('/bots')}>
                                        <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                                            <ListItemIcon>
                                                <AdbIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Bots" />
                                        </ListItemButton>
                                    </ListItem>
                                </Fragment> : null}
                            <ListItem button component={Link} to="/handles" selected={isPathSelected('/handles')}>
                                <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                                    <ListItemIcon>
                                        <ManageAccountsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Handles" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Drawer>
                    <Paper sx={{ flexGrow: 1, alignSelf: 'stretch', p: 0, overflowY: 'scroll' }}>
                        <Outlet />
                    </Paper>
                </Paper> : undefined}
        </Fragment>
    );
}

export default MainPage;

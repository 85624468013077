import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createHandle } from './actions';
import { useNavigate } from 'react-router-dom';
import HandleEditForm from './HandleEditForm';
import Paper from '@mui/material/Paper';
import { mainPageRootStyle } from '../styles';

const HandleAddPage = () => {
    const [handle, setHandle] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const handleHandleChange = useCallback((a) => {
        setHandle(a);
    }, []);
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(createHandle(handle, navigate));
    }, [handle, dispatch, navigate]);
    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h2>Add Handle</h2>
            <HandleEditForm
                handleSubmit={handleSubmit}
                handle={handle}
                handleHandleChange={handleHandleChange}
            />
        </Paper>
    );
};
export default HandleAddPage;
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { mainPageRootStyle } from '../styles';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBots, updateBots } from './actions';
import BotsConfigEditForm from './BotsConfigEditForm';


function BotsConfigPage() {
    const dispatch = useDispatch();
    const handles = useSelector(state => state.handle.handles);
    const botsConfigRedux = useSelector(state => state.bots.botsConfigByHandle);
    const userId = useSelector(state => state.auth.user?.uid);
    const navigate = useNavigate();
    const [botsConfig, setBotsConfig] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedHandle = queryParams.get('handle');

    useEffect(() => {
        const handle = handles.find(handle => handle.handle === selectedHandle);

        if (handle) {
            dispatch(getBots(handle.id));
        }
    }, [dispatch, selectedHandle, handles]);

    useEffect(() => {
        if (botsConfigRedux) {
            setBotsConfig({ ...botsConfigRedux });
        }
    }, [botsConfigRedux]);

    const handleSelectedHandleChange = (event) => {
        navigate(`/bots?handle=${event.target.value}`);
    };

    const handleSubmit = (event) => {
        console.log('handleSubmit');
        const handle = handles.find(handle => handle.handle === selectedHandle);
        console.log(handle);

        if (handle) {
            dispatch(updateBots(handle.id, handle.handle, botsConfig, userId));
        }
    };

    const handleCancel = () => {
        const handle = handles.find(handle => handle.handle === selectedHandle);

        if (handle) {
            dispatch(getBots(handle.id, handle.handle, botsConfig, userId));
        }
    };

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h1>Growth Dashboard</h1>
            <Select
                value={selectedHandle}
                onChange={handleSelectedHandleChange}
                label="Handle"
                sx={{ mt: 2, mb: 2, maxWidth: 600 }}
            >
                {(handles ?? []).map(handle => {
                    return <MenuItem key={handle.handle} value={handle.handle}>{handle.handle}</MenuItem>
                })}
            </Select>
            {botsConfig ? <BotsConfigEditForm botsConfig={botsConfig} handleChange={setBotsConfig} handleSubmit={handleSubmit} handleCancel={handleCancel} /> : undefined}
        </Paper >
    );
}

export default BotsConfigPage;

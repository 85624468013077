import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteHandle } from './actions';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { mainPageRootStyle } from '../styles';
import { Link } from 'react-router-dom';

const HandlesListPage = () => {
    const dispatch = useDispatch();
    const handles = useSelector(state => state.handle.handles);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [handleToDelete, setHandleToDelete] = useState(null);

    const handleDelete = (handle) => {
        setHandleToDelete(handle);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        dispatch(deleteHandle(handleToDelete.id));
        setDeleteDialogOpen(false);
    };

    const cancelDelete = () => {
        setHandleToDelete(null);
        setDeleteDialogOpen(false);
    };

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h1>Handles</h1>
            <Button variant="contained" component={Link} to="/handles/add" sx={{ alignSelf: 'flex-start', mb: 2 }}>
                Add Handle
            </Button>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(handles ?? []).map((handle) => (
                            <TableRow
                                key={handle.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{handle.handle}</TableCell>
                                <TableCell align="right">
                                    <IconButton component={Link} to={`/handles/edit/${handle.id}`}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(handle)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={deleteDialogOpen}
                onClose={cancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete handle '{handleToDelete?.handle}'?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete}>Cancel</Button>
                    <Button onClick={confirmDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default HandlesListPage;

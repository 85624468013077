import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHandle, updateHandle } from './actions';
import { useNavigate, useParams } from 'react-router-dom';
import HandleEditForm from './HandleEditForm';
import Paper from '@mui/material/Paper';
import { mainPageRootStyle } from '../styles';

const HandleEditPage = () => {
    const [handle, setHandle] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleId } = useParams();

    const handleRedux = useSelector(state => state.handle.handle);

    useEffect(() => {
        dispatch(getHandle(handleId));
    }, [handleId, dispatch]);

    useEffect(() => {
        if (handleRedux) {
            setHandle({ ...handleRedux });
        }
    }, [handleRedux]);

    const handleHandleChange = useCallback(a => {
        setHandle(a);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(updateHandle(handleId, handle, navigate));
    }, [handleId, handle, navigate, dispatch]);

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h2>Edit Handle</h2>
            <HandleEditForm
                handleSubmit={handleSubmit}
                handle={handle}
                handleHandleChange={handleHandleChange}
            />
        </Paper>
    );
};

export default HandleEditPage;


import { Fragment } from "react";
import "./Overlay.css";

export function Overlay({ isOpen, children }) {
    return (
        <Fragment>
            {isOpen && (
                <div className="overlay">
                    <div className="overlay__container">
                        {children}
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default Overlay;
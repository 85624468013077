import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { mainPageRootStyle } from '../styles';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadGrowthByHandle } from './actions';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { predictFollowersCount } from '../utils/predictionUtils';
import { formatNumber } from '../utils/numberUtils';


function DashboardPage() {
    const dispatch = useDispatch();
    const handles = useSelector(state => state.handle.handles);
    const growth = useSelector(state => state.growth.growthByHandle);
    const userId = useSelector(state => state.auth.user?.uid);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedHandle = queryParams.get('handle');

    useEffect(() => {
        dispatch(loadGrowthByHandle(userId, selectedHandle));
    }, [dispatch, selectedHandle, userId]);

    const handleSelectedHandleChange = (event) => {
        navigate(`/growth?handle=${event.target.value}`);
    };

    const renderChart = (dataKey, name) => {
        return <div style={{ width: '100%', maxWidth: '1000px', height: '300px', margin: '0 auto' }}>
            <ResponsiveContainer>
                <LineChart
                    data={growthWithDateObjects}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 10,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={(value) => new Date(value).toLocaleDateString()} />
                    <YAxis />
                    <Tooltip
                        labelFormatter={(value) => new Date(value).toLocaleDateString()}
                    />
                    <Legend />
                    <Line type="monotone" dataKey={dataKey} name={name} stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    };

    const growthWithDateObjects = (growth ?? []).map(item => ({
        ...item,
        date: new Date(item.date),
    }));
    growthWithDateObjects.sort((a, b) => a.date - b.date);

    const [followersIn3Months, followersIn6Months, followersIn1Year] = predictFollowersCount(growthWithDateObjects);

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h1>Growth Dashboard</h1>
            <Select
                value={selectedHandle}
                onChange={handleSelectedHandleChange}
                label="Handle"
                sx={{ mt: 2, mb: 2, maxWidth: 600 }}
            >
                {(handles ?? []).map(handle => {
                    return <MenuItem key={handle.handle} value={handle.handle}>{handle.handle}</MenuItem>
                })}
            </Select>
            <p>Estimated followers in 3 months: <b>{formatNumber(followersIn3Months)}</b>, 6 months: <b>{formatNumber(followersIn6Months)}</b>, 1 year: <b>{formatNumber(followersIn1Year)}</b></p>
            {renderChart('stat.followersCount', 'Followers')}
            {renderChart('stat.followsCount', 'Followings')}
            {renderChart('stat.postsCount', 'Posts')}
            {renderChart('stat.newFollowsCount', 'New Follows')}
            {renderChart('stat.newLikesCount', 'New Likes')}
            {renderChart('stat.newMentionsCount', 'New Mentions')}
            {renderChart('stat.newQuotesCount', 'New Quotes')}
            {renderChart('stat.newReplysCount', 'New Replys')}
            {renderChart('stat.newRepostsCount', 'New Reposts')}
        </Paper >
    );
}

export default DashboardPage;

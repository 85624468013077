
import { generateKey } from "./../utils/keyUtils";
import { getFirestore, collection, getDocs, doc, getDoc, where, query } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from './../firebase';

// Handle actions
export const CREATE_HANDLE_START = 'CREATE_HANDLE_START';
export const CREATE_HANDLE_SUCCESS = 'CREATE_HANDLE_SUCCESS';
export const CREATE_HANDLE_ERROR = 'CREATE_HANDLE_ERROR';
export const LOAD_HANDLES_START = 'LOAD_HANDLES_START';
export const LOAD_HANDLES_SUCCESS = 'LOAD_HANDLES_SUCCESS';
export const LOAD_HANDLES_ERROR = 'LOAD_HANDLES_ERROR';
export const DELETE_HANDLE_START = 'DELETE_HANDLE_START';
export const DELETE_HANDLE_SUCCESS = 'DELETE_HANDLE_SUCCESS';
export const DELETE_HANDLE_ERROR = 'DELETE_HANDLE_ERROR';
export const GET_HANDLE_START = 'GET_HANDLE_START';
export const GET_HANDLE_SUCCESS = 'GET_HANDLE_SUCCESS';
export const GET_HANDLE_ERROR = 'GET_HANDLE_ERROR';
export const UPDATE_HANDLE_START = 'UPDATE_HANDLE_START';
export const UPDATE_HANDLE_SUCCESS = 'UPDATE_HANDLE_SUCCESS';
export const UPDATE_HANDLE_ERROR = 'UPDATE_HANDLE_ERROR';

// Create handle actions
export const createHandleStart = () => {
    return { type: CREATE_HANDLE_START };
};
export const createHandleSuccess = () => {
    return { type: CREATE_HANDLE_SUCCESS };
};
export const createHandleError = (errorMessage) => {
    return { type: CREATE_HANDLE_ERROR, payload: errorMessage };
};

export const createHandle = (handle, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createHandleStart());
            const functions = getFunctions(firebaseApp);
            const createHandleFunction = httpsCallable(functions, 'createHandle');
            const handleId = generateKey();
            const handleData = {
                ...handle,
                id: handleId,
            };
            await createHandleFunction(handleData);
            navigate('/handles');
            dispatch(createHandleSuccess());
        } catch (error) {
            dispatch(createHandleError(error.message));
        }
    };
};

// Load handles actions
export const loadHandlesStart = () => {
    return { type: LOAD_HANDLES_START };
};
export const loadHandlesSuccess = (handles) => {
    return { type: LOAD_HANDLES_SUCCESS, payload: handles };
};
export const loadHandlesError = (errorMessage) => {
    return { type: LOAD_HANDLES_ERROR, payload: errorMessage };
};
export const loadHandles = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(loadHandlesStart());
            const firestore = getFirestore(firebaseApp);
            const querySnapshot = await getDocs(query(collection(firestore, "handles"), where("userId", "==", userId)));
            const handles = querySnapshot.docs.map((doc) => doc.data());
            dispatch(loadHandlesSuccess(handles));
        } catch (error) {
            dispatch(loadHandlesError(error.message));
        }
    };
};

// Delete handle actions
export const deleteHandleStart = () => {
    return { type: DELETE_HANDLE_START };
};
export const deleteHandleSuccess = () => {
    return { type: DELETE_HANDLE_SUCCESS };
};
export const deleteHandleError = (errorMessage) => {
    return { type: DELETE_HANDLE_ERROR, payload: errorMessage };
};
export const deleteHandle = (handleId) => {
    return async (dispatch) => {
        try {
            dispatch(deleteHandleStart());
            const functions = getFunctions();
            const deleteHandleFunction = httpsCallable(functions, 'deleteHandle');
            const handleData = {
                handleId,
            };
            await deleteHandleFunction(handleData);
            dispatch(deleteHandleSuccess());
            dispatch(loadHandles());
        } catch (error) {
            dispatch(deleteHandleError(error.message));
        }
    };
};

// Get handles by ID actions
export const getHandleStart = () => {
    return { type: GET_HANDLE_START };
};
export const getHandleSuccess = (handle) => {
    return { type: GET_HANDLE_SUCCESS, payload: handle };
};
export const getHandleError = (errorMessage) => {
    return { type: GET_HANDLE_ERROR, payload: errorMessage };
};
export const getHandle = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getHandleStart());
            const firestore = getFirestore(firebaseApp);
            const handleRef = doc(firestore, "handles", id);
            const handleSnapshot = await getDoc(handleRef);
            if (handleSnapshot.exists()) {
                const handle = handleSnapshot.data();
                dispatch(getHandleSuccess(handle));
            } else {
                dispatch(getHandleError("Handle not found"));
            }
        } catch (error) {
            dispatch(getHandleError(error.message));
        }
    };
};

// Update handle actions
export const updateHandleStart = () => {
    return { type: UPDATE_HANDLE_START };
};
export const updateHandleSuccess = () => {
    return { type: UPDATE_HANDLE_SUCCESS };
};
export const updateHandleError = (errorMessage) => {
    return { type: UPDATE_HANDLE_ERROR, payload: errorMessage };
};
export const updateHandle = (handleId, handle, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(updateHandleStart());
            const functions = getFunctions(firebaseApp);
            const updateHandleFunction = httpsCallable(functions, 'updateHandle');
            const handleData = {
                ...handle,
                handleId,
            };
            await updateHandleFunction(handleData);
            dispatch(updateHandleSuccess());
            dispatch(loadHandles());
            navigate('/handles');
        } catch (error) {
            dispatch(updateHandleError(error.message));
        }
    };
};

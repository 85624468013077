import { styled } from '@mui/material';

export const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
};

export const mainPageRootStyle = {
    with: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',

    h2: {
        alignSelf: 'center',
    }
}

export const selectedImageStyle = {
    height: '400px',
    marginBottom: '8px',
    marginTop: '8px',
};

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyARNJNEwLS3oQALhiU90oNMplype8C2bU0",
    authDomain: "sky-toolbox.firebaseapp.com",
    projectId: "sky-toolbox",
    storageBucket: "sky-toolbox.appspot.com",
    messagingSenderId: "932269734036",
    appId: "1:932269734036:web:11ec572b2f2740d39adc81",
    measurementId: "G-XPQWWGK616"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const googleAnalytics = getAnalytics(firebaseApp);
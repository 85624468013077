import React, { useCallback, useState } from 'react';
import { Button, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import { formStyle } from '../styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const HandleEditForm = ({ handleSubmit, handle, handleHandleChange }) => {
    const [changed, setChanged] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleHandleHandleChange = useCallback((e) => {
        handleHandleChange({ ...handle, handle: e.target.value });
        setChanged(true);
    }, [handle, handleHandleChange]);

    const handlePasswordChange = useCallback((e) => {
        handleHandleChange({ ...handle, password: e.target.value });
        setChanged(true);
    }, [handle, handleHandleChange]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };


    return (
        <form style={formStyle}>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="outlined-handle">Handle</InputLabel>
                <OutlinedInput
                    id="outlined-handle"
                    type='text'
                    value={handle?.handle || ""}
                    onChange={handleHandleHandleChange}
                    label="Handle"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={handle?.password || ""}
                    onChange={handlePasswordChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <Button type="submit" variant="contained" onClick={handleSubmit} disabled={!changed || !handle?.handle || !handle.password} style={{ alignSelf: 'center' }}>Save</Button>
        </form>
    );
};

export default HandleEditForm;
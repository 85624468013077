import React, { useCallback, useState } from 'react';
import { Button, OutlinedInput, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { formStyle } from '../styles';

const BotsConfigEditForm = ({ handleSubmit, handleCancel, botsConfig, handleChange }) => {
    const [changed, setChanged] = useState(false);

    const handleChangeInternal = useCallback(newValue => {
        handleChange(newValue);
        setChanged(true);
    }, [handleChange]);

    return (
        <div style={formStyle}>
            <h3 style={{ marginLeft: '10px' }}>Bots Configuration</h3>

            <h4 style={{ marginLeft: '10px' }}>Schedule</h4>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="min-delay-hours">Min Delay Hours</InputLabel>
                <OutlinedInput
                    type='number'
                    id='min-delay-hours'
                    value={botsConfig?.schedule.minDelayHours}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, schedule: { ...botsConfig.schedule, minDelayHours: e.target.value } })}
                    label="Min Delay Hours"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="max-delay-hours">Max Delay Hours</InputLabel>
                <OutlinedInput
                    type='number'
                    id='max-delay-hours'
                    value={botsConfig?.schedule.maxDelayHours}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, schedule: { ...botsConfig.schedule, maxDelayHours: e.target.value } })}
                    label="Max Delay Hours"
                />
            </FormControl>

            <h4 style={{ marginLeft: '10px' }}>Feeds bot</h4>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <FormControlLabel control={<Checkbox checked={botsConfig?.feedsBot?.enabled} onChange={(e) => handleChangeInternal({ ...botsConfig, feedsBot: { ...botsConfig.feedsBot, enabled: e.target.checked } })} />} label="Enabled" />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="feed-like-perc">Like Percentage</InputLabel>
                <OutlinedInput
                    type='number'
                    id='feed-like-perc'
                    value={botsConfig?.feedsBot.likePercantage}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, feedsBot: { ...botsConfig.feedsBot, likePercantage: e.target.value } })}
                    label="Like Percentage"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="feed-like-limit">Like Limit</InputLabel>
                <OutlinedInput
                    type='number'
                    id='feed-like-limit'
                    value={botsConfig?.feedsBot.limit}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, feedsBot: { ...botsConfig.feedsBot, limit: e.target.value } })}
                    label="Like Limit"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="feed-follow-perc">Follow Percentage</InputLabel>
                <OutlinedInput
                    type='number'
                    id='feed-follow-perc'
                    value={botsConfig?.feedsBot.followPercantage}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, feedsBot: { ...botsConfig.feedsBot, followPercantage: e.target.value } })}
                    label="Follow Percentage"
                />
            </FormControl>

            <h4 style={{ marginLeft: '10px' }}>Timeline bot</h4>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <FormControlLabel control={<Checkbox checked={botsConfig?.timelineBot?.enabled} onChange={(e) => handleChangeInternal({ ...botsConfig, timelineBot: { ...botsConfig.timelineBot, enabled: e.target.checked } })} />} label="Enabled" />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="timeline-like-perc">Like Percentage</InputLabel>
                <OutlinedInput
                    type='number'
                    id='timeline-like-perc'
                    value={botsConfig?.timelineBot.likePercantage}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, timelineBot: { ...botsConfig.timelineBot, likePercantage: e.target.value } })}
                    label="Like Percentage"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="timeline-like-limit">Like Limit</InputLabel>
                <OutlinedInput
                    type='number'
                    id='timeline-like-limit'
                    value={botsConfig?.timelineBot.limit}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, timelineBot: { ...botsConfig.timelineBot, limit: e.target.value } })}
                    label="Like Limit"
                />
            </FormControl>

            <h4 style={{ marginLeft: '10px' }}>Search Bot</h4>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <FormControlLabel control={<Checkbox checked={botsConfig?.searchBot?.enabled} onChange={(e) => handleChangeInternal({ ...botsConfig, searchBot: { ...botsConfig.searchBot, enabled: e.target.checked } })} />} label="Enabled" />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="queries-like-perc">Queries (comma separated)</InputLabel>
                <OutlinedInput
                    type='text'
                    id='queries-like-perc'
                    value={botsConfig?.searchBot.queries}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, searchBot: { ...botsConfig.searchBot, queries: e.target.value } })}
                    label="Queries (comma separated)"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="search-like-perc">Like Percentage</InputLabel>
                <OutlinedInput
                    type='number'
                    id='search-like-perc'
                    value={botsConfig?.searchBot.likePercantage}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, searchBot: { ...botsConfig.searchBot, likePercantage: e.target.value } })}
                    label="Like Percentage"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="search-like-limit">Like Limit</InputLabel>
                <OutlinedInput
                    type='number'
                    id='search-like-limit'
                    value={botsConfig?.searchBot.limit}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, searchBot: { ...botsConfig.searchBot, limit: e.target.value } })}
                    label="Like Limit"
                />
            </FormControl>

            <h4 style={{ marginLeft: '10px' }}>Unfollow Bot</h4>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <FormControlLabel control={<Checkbox checked={botsConfig?.unfollowBot?.enabled} onChange={(e) => handleChangeInternal({ ...botsConfig, unfollowBot: { ...botsConfig.unfollowBot, enabled: e.target.checked } })} />} label="Enabled" />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="unfollow-bot-min">Min Users to unfollow</InputLabel>
                <OutlinedInput
                    type='number'
                    id='unfollow-bot-min'
                    value={botsConfig?.unfollowBot.unfollowCountMin}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, unfollowBot: { ...botsConfig.unfollowBot, unfollowCountMin: e.target.value } })}
                    label="Min Users to unfollow"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: 300 }} variant="outlined" margin='normal'>
                <InputLabel htmlFor="unfollow-bot-max">Max Users to unfollow</InputLabel>
                <OutlinedInput
                    type='number'
                    id='unfollow-bot-max'
                    value={botsConfig?.unfollowBot.unfollowCountMax}
                    onChange={(e) => handleChangeInternal({ ...botsConfig, unfollowBot: { ...botsConfig.unfollowBot, unfollowCountMax: e.target.value } })}
                    label="Max Users to unfollow"
                />
            </FormControl>

            <Button sx={{ m: 1 }} type="button" variant="contained" onClick={handleSubmit} disabled={!changed} style={{ alignSelf: 'center' }}>Save</Button>
            <Button sx={{ m: 1 }} type="button" variant="contained" onClick={handleCancel} disabled={!changed} style={{ alignSelf: 'center' }}>Cancel</Button>
        </div>
    );
};

export default BotsConfigEditForm;
import regression from 'regression';

export function predictFollowersCount(data) {
    if (data.length === 0) return [0, 0, 0];

    const startDate = new Date(data[0].date);
    const series = data.map(item => [
        (new Date(item.date) - startDate) / (1000 * 60 * 60 * 24), // Convert milliseconds to days
        item.stat?.followersCount || 0,
    ]);

    // Create linear regression model
    const result = regression.linear(series);

    // Function to predict followers count
    const predictFollowers = (date) => Math.round(result.predict(
        (new Date(date) - startDate) / (1000 * 60 * 60 * 24)
    )[1]);

    const now = new Date();
    const in3Months = new Date(now.getFullYear(), now.getMonth() + 3, now.getDate());
    const in6Months = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
    const in1Year = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

    const followersIn3Months = predictFollowers(in3Months);
    const followersIn6Months = predictFollowers(in6Months);
    const followersIn1Year = predictFollowers(in1Year);

    return [followersIn3Months, followersIn6Months, followersIn1Year];
}
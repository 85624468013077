import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { mainPageRootStyle } from './../styles';
import { createPost } from './actions';
import PostEditForm from './PostEditForm';
import dayjs from 'dayjs';

const PostAddPage = () => {
    const userId = useSelector(state => state.auth.user?.uid);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedHandle = queryParams.get('handle');

    const [post, setPost] = useState({ handle: selectedHandle, scheduledFor: dayjs().add(1, "day").toISOString() });
    const [images, setImages] = useState([]);
    const [photoSrcs, setPhotoSrcs] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handlePostChange = useCallback((p) => {
        setPost(p);
    }, []);

    const handleImagesChange = useCallback((files) => {
        setPhotoSrcs(files.map(f => f.fileURL));
        setImages(files.map(f => f.file));
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(createPost(post, images, userId, navigate));
    }, [post, images, userId, navigate, dispatch]);

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h2>Add Photo</h2>
            <PostEditForm
                handleSubmit={handleSubmit}
                post={post}
                handlePostChange={handlePostChange}
                photoSrcs={photoSrcs}
                handleImagesChange={handleImagesChange}
            />
        </Paper>
    );
};

export default PostAddPage;

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { mainPageRootStyle } from './../styles';
import { getPost, updatePost } from './actions';
import PostEditForm from './PostEditForm';
import dayjs from 'dayjs';

const PostEditPage = () => {
    const userId = useSelector(state => state.auth.user?.uid);
    const [post, setPost] = useState(null);
    const [images, setImages] = useState([]);
    const [photoSrcs, setPhotoSrcs] = useState([]);
    const [updateImages, setUpdateImages] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { postId } = useParams();

    const postRedux = useSelector(state => state.scheduler?.post);

    useEffect(() => {
        dispatch(getPost(postId));
    }, [dispatch, postId]);

    useEffect(() => {
        if (postRedux) {
            setPost({ ...postRedux });
            setPhotoSrcs(postRedux.imageUrls);
        }
    }, [postRedux]);

    const handlePostChange = useCallback((p) => {
        setPost(p);
    }, []);

    const handleImagesChange = useCallback(files => {
        setPhotoSrcs(files.map(f => f.fileURL));
        setImages(files.map(f => f.file));
        setUpdateImages(true);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(updatePost(postId, post, images, userId, updateImages, navigate));
    }, [postId, post, images, userId, updateImages, navigate, dispatch]);

    return (
        <Paper style={mainPageRootStyle} sx={{ p: 2 }}>
            <h2>Edit Photo</h2>
            <PostEditForm
                handleSubmit={handleSubmit}
                post={post}
                handlePostChange={handlePostChange}
                photoSrcs={photoSrcs}
                handleImagesChange={handleImagesChange}
            />
        </Paper>
    );
};

export default PostEditPage;


import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { firebaseApp } from './../firebase';

// Bots actions
export const GET_BOTS_START = 'GET_BOTS_START';
export const GET_BOTS_SUCCESS = 'GET_BOTS_SUCCESS';
export const GET_BOTS_ERROR = 'GET_BOTS_ERROR';
export const UPDATE_BOTS_START = 'UPDATE_BOTS_START';
export const UPDATE_BOTS_SUCCESS = 'UPDATE_BOTS_SUCCESS';
export const UPDATE_BOTS_ERROR = 'UPDATE_BOTS_ERROR';

const defaultBotsConfig = {
    schedule: {
        minDelayHours: 8,
        maxDelayHours: 12,
    },
    feedsBot: {
        enabled: false,
        likePercantage: 35,
        limit: 50,
        followPercantage: 10
    },
    timelineBot: {
        enabled: false,
        likePercantage: 55,
        limit: 50,
    },
    searchBot: {
        enabled: false,
        queries: "",
        likePercantage: 30,
        limit: 50,
    },
    unfollowBot: {
        enabled: false,
        unfollowCountMin: 3,
        unfollowCountMax: 5,
    },
};

// Get handles by ID actions
export const getBotsStart = () => {
    return { type: GET_BOTS_START };
};
export const getBotsSuccess = (handle) => {
    return { type: GET_BOTS_SUCCESS, payload: handle };
};
export const getBotsError = (errorMessage) => {
    return { type: GET_BOTS_ERROR, payload: errorMessage };
};
export const getBots = (handleId) => {
    return async (dispatch) => {
        try {
            dispatch(getBotsStart());
            const firestore = getFirestore(firebaseApp);
            const botsRef = doc(firestore, "bots", handleId);
            const botsSnapshot = await getDoc(botsRef);
            if (botsSnapshot.exists()) {
                const botsConfig = botsSnapshot.data();
                dispatch(getBotsSuccess(botsConfig));
            } else {
                dispatch(getBotsSuccess(defaultBotsConfig));
            }
        } catch (error) {
            dispatch(getBotsError(error.message));
        }
    };
};

// Update bots actions
export const updateBotsStart = () => {
    return { type: UPDATE_BOTS_START };
};
export const updateBotsSuccess = () => {
    return { type: UPDATE_BOTS_SUCCESS };
};
export const updateBotsError = (errorMessage) => {
    return { type: UPDATE_BOTS_ERROR, payload: errorMessage };
};
export const updateBots = (handleId, handle, botsConfig, userId) => {
    return async (dispatch) => {
        try {
            dispatch(updateBotsStart());
            const firestore = getFirestore(firebaseApp);
            const botsRef = doc(firestore, "bots", handleId);
            const botsDoc = await getDoc(botsRef);
            let botsData = botsDoc.exists() ? botsDoc.data() : { userId, handleId, handle };
            botsData = { ...botsData, ...botsConfig };
            await setDoc(botsRef, botsData);
            dispatch(updateBotsSuccess());
            dispatch(getBots(handleId));
        } catch (error) {
            dispatch(updateBotsError(error.message));
        }
    };
};

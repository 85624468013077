import { firebaseApp } from "../firebase";
import { formatDate } from "./dateUtils";
import { getFileExtension } from "./fileUtils";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { generateKey } from "./keyUtils";

export async function uploadToStorage(file, post, id, userId) {
    const storage = getStorage(firebaseApp);

    const imageKey = generateKey();
    const storagePath = `posts/${userId}/${id}/${post.handle}/${formatDate(post.scheduledFor)}-${id}/${imageKey}.${getFileExtension(file.name)}`;
    const imageRef = ref(storage, storagePath);
    await uploadBytes(imageRef, await resizeAndCompressImage(file));

    return storagePath;
}

async function resizeAndCompressImage(file) {
    if (!file) {
        throw new Error("No file provided");
    }

    const reader = new FileReader();

    // Create a promise to handle the FileReader onload event
    const loadFile = new Promise((resolve, reject) => {
        reader.onload = resolve;
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    const dataURL = await loadFile.then(e => e.target.result);

    const img = new Image();

    // Create a promise to handle the image onload event
    const loadImage = new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = dataURL;
    });

    await loadImage;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const max_width = 1200;
    const scale = max_width / img.width;
    canvas.width = max_width;
    canvas.height = img.height * scale;

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Function to handle blob creation with adjustable quality
    const createBlob = (quality) => new Promise((resolve, reject) => {
        canvas.toBlob(resolve, 'image/jpeg', quality);
    });

    // Gradually reduce the quality to get the smallest possible file size under 1MB
    let quality = 1.0;  // Start with the highest quality
    let step = 0.1;     // Initial step for quality reduction
    let blob = await createBlob(quality);

    while (blob.size > 1024 * 1024 && quality > 0) {
        quality -= step;  // Reduce quality
        blob = await createBlob(quality);

        if (quality <= 0.1 && step > 0.01) {
            // If quality is very low, reduce the step size for finer adjustments
            step = 0.01;
        }
    }

    return blob;
}

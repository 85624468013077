const initialState = {
    operationsCounter: 0
};

export function operations(state = initialState, action) {
    if (action.type.endsWith("_START")) {
        return {
            ...state,
            operationsCounter: state.operationsCounter + 1
        };
    }

    if (action.type.endsWith("_SUCCESS") || action.type.endsWith("_ERROR")) {
        return {
            ...state,
            operationsCounter: state.operationsCounter - 1
        };
    }

    return state;
};
